const detailedModal = () => {
  const detailedModalElements = document.querySelectorAll("[data-action*='detailed_modal']");

  for(const element of detailedModalElements){
    element.addEventListener('click', function(){
      const detailsToShow = this.getAttribute('data-details');
      const modalToComplete = document.querySelector('#detailsModal #details');

      modalToComplete.innerHTML = detailsToShow
    })
  }
}

export { detailedModal };