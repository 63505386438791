const readMore = () => {
  const readMoreElements = document.querySelectorAll("[data-action*='read_more']");

  for(const element of readMoreElements){
    element.addEventListener('click', function(){
      const elementContainingData = this.parentNode.querySelector(".data-element")

      const textToShow = elementContainingData.getAttribute('data-full');
      const textToHide = elementContainingData.innerHTML;

      elementContainingData.innerHTML = textToShow.replaceAll("<!","")
      elementContainingData.dataset.full = textToHide
      if(this.textContent == "read more"){
        this.textContent = "read less"
      }else{
        this.textContent = "read more"
      }
    })
  }
}

export { readMore };